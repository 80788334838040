.cta-wrapper {
  @media (min-width: 576px) and (max-width: 992px) {
    width: 100%;
  }

  @media (min-width: 992px) {
    width: 50%;
  }
}
.cta-container {
  border-radius: 1.5em;
  min-width: 18em;
  min-height: 12em;
  display: flex;
  justify-content: center;
  background: rgb(255, 203, 75);
  background: linear-gradient(
    140deg,
    rgba(255, 203, 75, 1) 35%,
    rgba(246, 150, 104, 1) 100%
  );
}

.cta-container:first-child {
  background: linear-gradient(
    320deg,
    rgba(255, 203, 75, 1) 35%,
    rgba(246, 150, 104, 1) 100%
  );
}

.call-to-action-button {
  filter: brightness(100%);
}
.call-to-action-button:hover {
  filter: brightness(70%);
}
