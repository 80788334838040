$primary: #ffcb4b;
$secondary-blue: #a6cee1;
$secondary-pink: #fcdbcb;
$accent-orange: #f69668;
$accent-navy: #132a4e;

$between-secondary-blue-navy: #5d7c98;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary-blue,
  "lightPink": $secondary-pink,
  "accentOrange": $accent-orange,
  "black": #000,
);

$font-family-base: "Raleway";

$link-color: $between-secondary-blue-navy;
$link-hover-color: $accent-navy;

@import "node_modules/bootstrap/scss/bootstrap";
