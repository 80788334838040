.price-text {
  font-family: "Darker Grotesque", sans-serif;
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: -10px;
}

.number-text {
  font-family: "Darker Grotesque", sans-serif;
}
