.list-number-text {
  font-family: "Bungee Shade", cursive;
  font-size: 12em;
}

.bullet-item-container:hover {
  cursor: pointer;

  .list-number-text {
    transform: scale(1.1);
    background: -webkit-linear-gradient(180deg, #f69668, #ffcb4b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
